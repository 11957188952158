@tailwind base;
@tailwind components;
@tailwind utilities;

.formLabel {
    font-weight: 600;
    margin-top: 1rem;
    display: block;
}
.imagesInfo {
    font-size: 0.9rem;
    opacity: 0.75;
}
.formInputFile {
  width: 100%;
}
.formInputFile::-webkit-file-upload-button {
  background-color: #319795;
  border: none;
  color: #ffffff;
  font-weight: 600;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  margin-right: 1rem;
}
