@keyframes gradient-shine {
    0% {
      background-position: 100% 0;
    }
    50% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  
  .animated-gradient-line {
    background-size: 200% 100%;
    animation: gradient-shine 2s infinite linear;
  }
  